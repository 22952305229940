<template>
  <div class="card">
    <DataTable
      card-as-tab
      class="card-body"
      :fields="fields"
      service="resubmission"
      :base-query="query"
      :search-field="false"
      @row-clicked="(item) => $router.push({name: 'ResubmitDetail', params: {resubmitId: item._id}})"
    >
      <template #customSearch>
        <CustomSearch @updateSearchQuery="searchQuery = $event" />
      </template>
      <template
        slot="orderNumber"
        slot-scope="{item: data}"
      >
        <router-link
          v-if="data.orderId"
          :to="{name: 'HSSOrderDetail', params: {orderId: data.orderId} }"
          target="_blank"
          @click.stop
        >
          {{ data.orderNumber }}
          <i class="fas fa-external-link" />
        </router-link>
      </template>
      <template
        slot="customer"
        slot-scope="{value: customer, unformatted: data}"
      >
        <router-link
          v-if="data._id"
          :to="{name: 'CustomerDetail', params: {customerId: data._id} }"
          target="_blank"
          @click.stop
        >
          {{ customer }}
          <i class="fas fa-external-link" />
        </router-link>
      </template>
      <template
        slot="subject"
        slot-scope="{item: data}"
      >
        <router-link
          v-if="data._id"
          :to="{name: 'ResubmitDetail', params: {resubmitId: data._id} }"
          target="_blank"
          @click.stop
        >
          {{ data.subject }}
          <i class="fas fa-external-link" />
        </router-link>
      </template>
    </datatable>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable/DataTable.vue'
import formatDate from '@/filters/formatDate'
import hsOrderStatus from '@/resources/enums/hsOrderStatus'
import resubmitCategory from '@/resources/enums/resubmitCategory'
import CustomSearch from './CustomSearch.vue'

export default {
  components: { CustomSearch, DataTable },
  props: {
    baseQuery: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      searchQuery: null
    }
  },
  computed: {
    fields () {
      const fields = [
        {
          label: 'Vertragsnummer',
          key: 'orderNumber',
          sortable: true
        },
        {
          label: 'Kunde',
          key: 'customer',
          formatter: customer => {
            if (!customer) return
            return `${customer.name.VOR ? customer.name.VOR : ''} ${customer.name.NAM}`
          },
          sortable: true
        },
        {
          label: 'Betreff',
          key: 'subject',
          sortable: true
        },
        {
          label: 'Kategorie',
          key: 'category',
          formatter: (category) => {
            if (!category || !resubmitCategory[category]) return ''
            return resubmitCategory[category].human
          },
          sortable: true
        },
        {
          label: 'Status',
          key: 'order.hsOrderStatus',
          formatter: (status) => {
            if (!status) return ''
            return hsOrderStatus[status].human
          },
          sortable: true
        },
        {
          label: 'Erstellt am',
          key: 'createdAt',
          formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
          sortable: true
        },
        {
          label: 'Fällig am',
          key: 'dueDate',
          formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
          sortable: true
        },
        {
          label: 'Mitarbeiter',
          key: 'bearbeiter',
          formatter: bearbeiter => {
            if (!bearbeiter) return
            return `${bearbeiter.firstname} ${bearbeiter.lastname}`
          },
          sortable: true
        }
      ]
      return fields
    },
    query () {
      return {
        ...this.baseQuery,
        ...this.searchQuery
      }
    }
  }
}
</script>
