<template>
  <div>
    <div class="container-fluid px-4 pt-4">
      <h3 class="mb-4">
        {{ heading }}
      </h3>
      <b-tabs class="tabs">
        <b-tab
          title="Aktuell"
          lazy
        >
          <ResubmitTable :base-query="{...baseQuery, dueDate: { $lte: dayjs().endOf('day').toDate()}, isDone: {$ne: true}}" />
        </b-tab>

        <b-tab
          title="Alle"
          lazy
        >
          <ResubmitTable :base-query="baseQuery" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import ResubmitTable from './ResubmitTable.vue'

export default {
  components: { ResubmitTable },
  props: {
    heading: {
      type: String,
      default: 'Wiedervorlagen'
    },
    baseQuery: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    dayjs
  }
}
</script>
