var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('DataTable',{staticClass:"card-body",attrs:{"card-as-tab":"","fields":_vm.fields,"service":"resubmission","base-query":_vm.query,"search-field":false},on:{"row-clicked":function (item) { return _vm.$router.push({name: 'ResubmitDetail', params: {resubmitId: item._id}}); }},scopedSlots:_vm._u([{key:"customSearch",fn:function(){return [_c('CustomSearch',{on:{"updateSearchQuery":function($event){_vm.searchQuery = $event}}})]},proxy:true},{key:"orderNumber",fn:function(ref){
var data = ref.item;
return [(data.orderId)?_c('router-link',{attrs:{"to":{name: 'HSSOrderDetail', params: {orderId: data.orderId} },"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(data.orderNumber)+" "),_c('i',{staticClass:"fas fa-external-link"})]):_vm._e()]}},{key:"customer",fn:function(ref){
var customer = ref.value;
var data = ref.unformatted;
return [(data._id)?_c('router-link',{attrs:{"to":{name: 'CustomerDetail', params: {customerId: data._id} },"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(customer)+" "),_c('i',{staticClass:"fas fa-external-link"})]):_vm._e()]}},{key:"subject",fn:function(ref){
var data = ref.item;
return [(data._id)?_c('router-link',{attrs:{"to":{name: 'ResubmitDetail', params: {resubmitId: data._id} },"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(data.subject)+" "),_c('i',{staticClass:"fas fa-external-link"})]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }