<template>
  <div>
    <ca-header
      heading="Dashboard"
    />
    <div class="container-fluid p-4">
      <ResubmitTabs
        class="mb-4"
        :base-query="{isEvbError: {$ne: true}}"
      />
      <ResubmitTabs
        heading="Nicht erstellte eVB"
        :base-query="{isEvbError: true}"
      />
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header.vue'
import ResubmitTabs from '../Resubmission/components/ResubmitTabs.vue'

export default {
  components: {
    CaHeader,
    ResubmitTabs
  },
  data: () => {
    return {
    }
  }
}
</script>
