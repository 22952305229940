<template>
  <div class="row align-items-center">
    <div class="col-auto font-weight-bold">
      Suche:
    </div>
    <BasicInput
      v-model="searchOrderNumber"
      placeholder="Vertragsnummer"
      :margin="false"
      type="search"
      class="col"
    />
    <BasicInput
      v-model="searchCustomer"
      placeholder="Kunde"
      :margin="false"
      type="search"
      class="col"
    />
    <BasicInput
      v-model="searchSubject"
      placeholder="Betreff"
      :margin="false"
      type="search"
      class="col"
    />
    <BasicInput
      v-model="searchCategory"
      placeholder="Kategorie"
      :margin="false"
      type="search"
      class="col"
    />
    <BasicInput
      v-model="searchStatus"
      placeholder="Status"
      :margin="false"
      type="search"
      class="col"
    />
    <BasicInput
      v-model="searchBearbeiter"
      placeholder="Bearbeiter"
      :margin="false"
      type="search"
      class="col"
    />
  </div>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput.vue'

export default {
  components: { BasicInput },
  emits: ['updateSearchQuery'],
  data () {
    return {
      searchOrderNumber: null,
      searchCustomer: null,
      searchSubject: null,
      searchCategory: null,
      searchStatus: null,
      searchBearbeiter: null
    }
  },
  computed: {
    searchQuery () {
      const query = { $client: { $lookup: {} } }
      // Felder in lookup sind nur als Fremdschlüssel vorhanden
      if (this.searchCustomer) {
        query.$client.$lookup.customerName = this.searchCustomer
      }
      if (this.searchBearbeiter) {
        query.$client.$lookup.bearbeiterName = this.searchBearbeiter
      }
      if (this.searchStatus) {
        query.$client.$lookup.orderStatus = this.searchStatus
      }
      // normal query
      if (this.searchOrderNumber) {
        query.orderNumber = { $regex: this.searchOrderNumber, $options: 'i' }
      }
      if (this.searchSubject) {
        query.subject = { $regex: this.searchSubject, $options: 'i' }
      }
      if (this.searchCategory) {
        query.category = { $regex: this.searchCategory, $options: 'i' }
      }
      return query
    }
  },
  watch: {
    searchQuery: {
      handler: function () {
        this.$emit('updateSearchQuery', this.searchQuery)
      }
    }
  }
}
</script>
